// import { Modules } from "./Modules";

// export var modules: Modules = new Modules();
// var timer = performance.now();
// var boolo = false;
// declare var window: any;
// modules.preInit();
// document.addEventListener("DOMContentLoaded", async function() {
//     try {
//         await boolVal();
//         console.log( performance.now());
//     }
//     catch(err) { console.log(err); }
// });

// const boolVal = async ()=> {
//     boolo = await modules.checkRequirements();
//     if ( boolo ) await modules.init();
// }

/** NEW */
window.ezmodules = modules;
// console.log('%c Script started: ' + performance.now(), 'background: #000; color: red');
import { Modules } from "./Modules";
export var modules: Modules = new Modules();
var boolo = false;
declare var window: any;
window.ezmodules = modules;
console.log('%c Script finished: ' + performance.now(), 'background: #000; color: red');


/**
 * What needs to be inside of the ressources.html file to make it work!
 * <script async src="/resources/modules/dynamic-bundle-prod/main.556e54f23069cf4311c3.bundle.js"></script>
<script async>
	console.log('%c HTML script start: ' + performance.now(), 'background: #000; color: orange');
	var compressedData; // Variable to store compressed data
	var staticVersionControl = '1.1';

	async function main() {
		try {
			const url = "/resources/modules/conf.yaml.gz";
			compressedData = await getFileFromCache('ConfYamlGZ');
			// If Found
			if( compressedData ) {
				console.log(' File found in cache.');
				console.log('%c HTML script finished: ' + performance.now(), 'background: #000; color: orange');
				console.log(document.readyState);
				startLoadingModules();
				return;
			}
			// Not found, try to laod and then give response
			if (!compressedData) {
				console.log("File not found in cache, fetching from URL...");
				compressedData = await fetchFileAndUpdateCache(url);
			}
			if (compressedData) {
				console.log("File fetched and cached successfully:", compressedData);
				console.log('%c HTML script finished: ' + performance.now(), 'background: #000; color: orange');
				console.log(document.readyState);
				startLoadingModules();
			} else {
				console.error('Failed to fetch or cache data');
			}
		} catch (error) {
			console.error('Error in main function:', error);
		}
	}
	// Check existenz
	function getFileFromCache(url) {
		const cachedData = localStorage.getItem(url);
		if (cachedData) {
			const { data, currentversion } = JSON.parse(cachedData);
			// const staticVersionControl = '1.1';
			// If current version
			if (currentversion  === staticVersionControl) {
				return Promise.resolve(data);
			}
			// If older version
			else {
				// Remove expired cache
				localStorage.removeItem(url);
				return Promise.resolve(null);
			}
		} else {
			return Promise.resolve(null);
		}
	}
	// Create new Cache data
	function cacheFile(data) {
		// const currentVersion = '1.1';
		const cacheObject = { data: Array.from(data), currentversion:staticVersionControl };
		localStorage.setItem('ConfYamlGZ', JSON.stringify(cacheObject));
	}

	// Fetch transformed data
	async function fetchFileAndUpdateCache(url) {
		try {
			const data = await fetchData(url);
			if (data) {
				cacheFile( data);
			}
			return data;
		} catch (error) {
			console.error('Error fetching and caching file:', error);
			return null;
		}
	}
	// Fetch data & transform in correct format
	async function fetchData(url) {
		try {
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const buffer = await response.arrayBuffer();
			return new Uint8Array(buffer);
		} catch (error) {
			console.error('Error fetching data:', error);
			return null;
		}
	}

	main();

	async function startLoadingModules() {
		const readyState =  document.readyState;

		if (readyState === 'loading') {
			window.addEventListener('DOMContentLoaded', async ()=> {
				if ( typeof window.ezmodules !== 'undefined') {
					executeModuleSys();
				}
				else {
					window.addEventListener('load', async ()=> {
						executeModuleSys();
					}, { once:true });
				}
			}, { once:true });
		}
		else {
			if ( typeof window.ezmodules !== 'undefined') {
				executeModuleSys();
			}
			else {
				if ( readyState !== 'complete' ) startLoadingModules();
			}
		}
	}
	async function executeModuleSys() {
		console.log('%c Script execute start: ' + performance.now(), 'background: #000; color: lime');
		boolo = await window.ezmodules.checkRequirements();
		if ( boolo ) {
			await window.ezmodules.init();
			console.log('%c  Script execute finished: ' +performance.now(), 'background: #000; color: lime');
		}
	}
</script>
*/